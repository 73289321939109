<template>
  <div
    class="surface-ground py-4 px-4 lg:px-8 mx-0 md:pt-8 mt-6 lg:mx-8"
    id="contact"
  >
    <div
      class="col-12 p-2 p-6"
      style="
        border-radius: 20px;
        background: linear-gradient(
            0deg,
            rgba(255, 255, 255, 0.6),
            rgba(255, 255, 255, 0.6)
          ),
          radial-gradient(
            77.36% 256.97% at 77.36% 57.52%,
            var(--ejtblue) 0%,
            white 100%
          );
      "
    >
      <div class="text-700 text-center">
        <div class="font-bold mb-3" style="color: var(--ejtblue)">
          <i class="pi pi-comments"></i>&nbsp;CONTACT
        </div>
        <div class="text-900 font-bold text-5xl mb-3">
          Contact met Team Eetjethuis!
        </div>
        <div class="text-700 text-2xl mb-5">
          Voor feedback, vragen en alle andere zaken met betrekking tot
          Eetjethuis.nl kunt u contact opnemen via onderstaande contact
          formulier.
        </div>
        <Button
          label="Neem contact op!"
          icon="pi pi-comments"
          class="font-bold px-5 py-3 p-button-raised p-button-rounded white-space-nowrap"
        ></Button>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "HomepageContact",
};
</script>

<template>
  <div
    id="hero"
    class="flex flex-column pt-4 px-4 lg:px-8 overflow-hidden"
    style="
      background: linear-gradient(
          0deg,
          rgba(255, 255, 255, 0.2),
          rgba(255, 255, 255, 0.2)
        ),
        radial-gradient(
          77.36% 256.97% at 77.36% 57.52%,
          var(--ejtblue) 0%,
          white 100%
        );
      clip-path: ellipse(150% 87% at 93% 13%);
    "
  >
    <div class="mx-4 md:mx-8 mt-0 md:mt-4 mb-4">
      <h1 class="text-6xl font-bold text-gray-700 line-height-2">
        <span class="font-light block">Welkom bij</span> Eetjethuis<span
          class="font-bold"
          style="color: var(--ejtblue)"
          >.nl</span
        >
      </h1>
      <p class="font-normal text-2xl line-height-3 md:mt-3 text-gray-700">
        De webapp om je familie of huisgenoten te laten weten, dat je graag mee
        wilt eten!
      </p>
      <a
        href="https://login.eetjethuis.nl/realms/eetjethuis/protocol/openid-connect/registrations?client_id=app-vue&redirect_uri=https%3A%2F%2Feetjethuis.nl%2Fapp%2F&state=9e5517e6-8f13-4c3a-86f8-12c49a982799&response_mode=fragment&response_type=code&scope=openid&nonce=1da7f9fb-8ce9-4322-b954-05b9151de9e5"
      >
        <Button
          label="Registreren"
          class="p-button-rounded p-button-raised text-xl border-none mt-5 font-normal text-white line-height-3 px-3"
        ></Button
      ></a>
    </div>
    <div class="flex justify-content-end md:justify-content-end">
      <img
        style="width: 5vw; max-width: 609px !important; height: auto"
        src="@/assets/images/Eetjethuis-app.png"
        alt="Hero Image"
        class="w-9 md:w-auto"
      />
    </div>
  </div>
</template>

<script>
export default {
  name: "HomepageLanding",
};
</script>

<style scoped>
.mr-31 {
  margin-right: 1rem;
}

.width-100 {
  width: 100vw;
}

@media (max-width: 767px) {
  .mt-6-0 {
    padding-top: 6rem !important;
  }
}
</style>

import { createApp } from "vue";
import App from "./App.vue";
import router from "./router";
import PrimeVue from "primevue/config";
import "@fortawesome/fontawesome-free/js/all";
import "primevue/resources/themes/lara-light-cyan/theme.css";
import "primeicons/primeicons.css";
import "primeflex/primeflex.css";
import "./assets/style.css";
import DataTable from "primevue/datatable";
import Column from "primevue/column";
import ColumnGroup from "primevue/columngroup";
import Row from "primevue/row";
import ConfirmDialog from "primevue/confirmdialog";
import ConfirmationService from "primevue/confirmationservice";
import Button from "primevue/button";
import TabMenu from "primevue/tabmenu";
import TabView from "primevue/tabview";
import TabPanel from "primevue/tabpanel";
import Toast from "primevue/toast";
import ToastService from "primevue/toastservice";
import Avatar from "primevue/avatar";
import Menu from "primevue/menu";
import InputSwitch from "primevue/inputswitch";
import DataView from "primevue/dataview";
import DataViewLayoutOptions from "primevue/dataviewlayoutoptions";
import InputText from "primevue/inputtext";
import Skeleton from "primevue/skeleton";
import InputMask from "primevue/inputmask";
import Calendar from "primevue/calendar";
import Dialog from "primevue/dialog";
import FileUpload from "primevue/fileupload";
import TieredMenu from "primevue/tieredmenu";
import InlineMessage from "primevue/inlinemessage";
import Divider from "primevue/divider";
import Textarea from "primevue/textarea";
import Panel from "primevue/panel";
import ProgressSpinner from "primevue/progressspinner";
import ScrollTop from "primevue/scrolltop";
import StyleClass from 'primevue/styleclass';

const app = createApp(App);
app.use(router);
app.use(PrimeVue);
app.use(ConfirmationService);
app.use(ToastService);
app.component("DataTable", DataTable);
app.component("Column", Column);
app.component("ColumnGroup", ColumnGroup);
app.component("Row", Row);
app.component("ConfirmDialog", ConfirmDialog);
app.component("Button", Button);
app.component("TabMenu", TabMenu);
app.component("TabView", TabView);
app.component("TabPanel", TabPanel);
app.component("Toast", Toast);
app.component("Avatar", Avatar);
app.component("Menu", Menu);
app.component("InputSwitch", InputSwitch);
app.component("DataView", DataView);
app.component("DataViewLayoutOptions", DataViewLayoutOptions);
app.component("InputText", InputText);
app.component("Skeleton", Skeleton);
app.component("InputMask", InputMask);
app.component("Calendar", Calendar);
app.component("Dialog", Dialog);
app.component("FileUpload", FileUpload);
app.component("TieredMenu", TieredMenu);
app.component("InlineMessage", InlineMessage);
app.component("Divider", Divider);
app.component("Textarea", Textarea);
app.component("Panel", Panel);
app.component("ProgressSpinner", ProgressSpinner);
app.component("ScrollTop", ScrollTop);
app.directive('styleclass', StyleClass);
app.mount("#app");

// Linkjes om te gebruiken
// Sidebar: https://www.vuescript.com/sidebar-navigation-component-for-vue/
// Check email validation: https://masteringjs.io/tutorials/vue/email-validation

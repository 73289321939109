<template>
  <div
    id="about"
    class="surface-card py-4 px-4 lg:px-8 mx-0 md:pt-6 mt-8 lg:mx-8 text-center"
  >
    <div class="text-center">
      <h2 class="text-900 font-bold text-5xl mb-2">Geschikt voor iedereen!</h2>
    </div>
    <div class="text-600 text-2xl mb-8">
      Of je nu nog thuis woont, kinderen hebt, uitwonende kinderen hebt die
      regelmatig thuis komen eten of in een studentenhuis woont, eetjethuis.nl
      past bij alle woonsituaties!
    </div>
    <div class="grid">
      <div class="col-12 md:col-4 mb-6 px-5">
        <span
          class="p-3 shadow-2 mb-3 inline-block surface-card"
          style="border-radius: 10px"
        >
          <i class="pi pi-check-circle text-4xl colorBlue"></i>
        </span>
        <div class="text-900 text-2xl mb-3 font-medium">
          Makkelijk te gebruiken
        </div>
        <span class="text-700 text-xl line-height-3"
          >De applicatie is zo ontwikkeld dat deze makkelijk te begrijpen is
          voor iedereen. Toch iets onduidelijk? Laat het ons weten!
        </span>
      </div>
      <div class="col-12 md:col-4 mb-6 px-5">
        <span
          class="p-3 shadow-2 mb-3 inline-block surface-card"
          style="border-radius: 10px"
        >
          <i class="fa-solid fa-house-lock text-4xl colorBlue"></i>
        </span>
        <div class="text-900 text-2xl mb-3 font-medium">Veilig</div>
        <span class="text-700 text-xl line-height-3"
          >Je gegevens zijn beveiligd in onze database. Niemand kan er bij,
          zelfs wij niet!
        </span>
      </div>
      <div class="col-12 md:col-4 mb-6 px-5">
        <span
          class="p-3 shadow-2 mb-3 inline-block surface-card"
          style="border-radius: 10px"
        >
          <i class="pi pi-desktop text-4xl colorBlue"></i>
        </span>
        <div class="text-900 text-2xl mb-3 font-medium">
          Continue optimalisering
        </div>
        <span class="text-700 text-xl line-height-3"
          >De app wordt continue verbeterd en uitgebreid. Heb je tips of dingen
          die je mist? Neem dan contact met ons op!
        </span>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "HomepagePlusses",
};
</script>

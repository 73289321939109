<template>
  <div
    class="surface-card py-4 px-4 lg:px-8 mx-0 md:pt-8 mt-6 lg:mx-8"
    id="stats"
  >
    <div class="text-900 font-bold text-center text-5xl mb-5">
      Meer over Eetjethuis.nl
    </div>
    <div class="grid">
      <div class="col-12 md:col-6 lg:col-4">
        <div class="surface-card shadow-2 p-3 border-round">
          <div class="flex justify-content-between mb-3">
            <div>
              <span class="block text-500 font-medium mb-3"
                >Actieve gebruikers</span
              >
              <div class="text-900 font-medium text-xl">8</div>
            </div>
            <div
              class="flex align-items-center justify-content-center bg-blue-100 border-round"
              style="width: 2.5rem; height: 2.5rem"
            >
              <i class="pi pi-user text-xl"></i>
            </div>
          </div>
          <span class="text-green-500 font-medium">8 </span>
          <span class="text-500">meer dan vorige week </span>
        </div>
      </div>
      <div class="col-12 md:col-6 lg:col-4">
        <div class="surface-card shadow-2 p-3 border-round">
          <div class="flex justify-content-between mb-3">
            <div>
              <span class="block text-500 font-medium mb-3"
                >Totaal aantal gebruikers</span
              >
              <div class="text-900 font-medium text-xl">8</div>
            </div>
            <div
              class="flex align-items-center justify-content-center bg-orange-100 border-round"
              style="width: 2.5rem; height: 2.5rem"
            >
              <i class="pi pi-user text-orange-500 text-xl"></i>
            </div>
          </div>
          <span class="text-green-500 font-medium">8</span>
          <span class="text-500"> meer dan vorige maand</span>
        </div>
      </div>
      <div class="col-12 md:col-6 lg:col-4">
        <div class="surface-card shadow-2 p-3 border-round">
          <div class="flex justify-content-between mb-3">
            <div>
              <span class="block text-500 font-medium mb-3"
                >Actieve huishoudens</span
              >
              <div class="text-900 font-medium text-xl">2</div>
            </div>
            <div
              class="flex align-items-center justify-content-center bg-cyan-100 border-round"
              style="width: 2.5rem; height: 2.5rem"
            >
              <i class="pi pi-home text-cyan-500 text-xl"></i>
            </div>
          </div>
          <span class="text-green-500 font-medium">2 </span>
          <span class="text-500">meer dan vorige maand</span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "HomepageStats",
};
</script>

<template>
  <div
    class="py-4 px-4 mx-0 md:mx-6 lg:mx-8 lg:px-8 flex align-items-center justify-content-between relative lg:static"
  >
    <a
      class="cursor-pointer block lg:hidden text-700"
      v-styleclass="{
        selector: '@next',
        enterClass: 'hidden',
        leaveToClass: 'hidden',
        hideOnOutsideClick: true,
      }"
    >
      <i class="pi pi-bars text-4xl"></i>
    </a>
    <div
      class="align-items-center surface-0 flex-grow-1 justify-content-between hidden lg:flex absolute lg:static w-full left-0 px-6 lg:px-0 z-2"
      style="top: 83px"
    >
      <ul
        class="list-none p-0 m-0 flex lg:align-items-center select-none flex-column lg:flex-row cursor-pointer"
      >
        <li>
          <a
            @click="smoothScroll('#hero')"
            class="flex m-0 md:ml-5 px-0 py-3 text-900 font-medium line-height-3"
          >
            <span>Home</span>
          </a>
        </li>
        <li>
          <a
            @click="smoothScroll('#devices')"
            class="flex m-0 md:ml-5 px-0 py-3 text-900 font-medium line-height-3"
          >
            <span>Devices</span>
          </a>
        </li>
        <li>
          <a
            @click="smoothScroll('#about')"
            class="flex m-0 md:ml-5 px-0 py-3 text-900 font-medium line-height-3"
          >
            <span>About</span>
          </a>
        </li>
        <li>
          <a
            @click="smoothScroll('#stats')"
            class="flex m-0 md:ml-5 px-0 py-3 text-900 font-medium line-height-3"
          >
            <span>Stats</span>
          </a>
        </li>
        <li>
          <a
            @click="smoothScroll('#contact')"
            class="flex m-0 md:ml-5 px-0 py-3 text-900 font-medium line-height-3"
          >
            <span>Contact</span>
          </a>
        </li>
        <li>
          <a
            @click="smoothScroll('#donations')"
            class="flex m-0 md:ml-5 px-0 py-3 text-900 font-medium line-height-3"
          >
            <span>Donation</span>
          </a>
        </li>
      </ul>
      <div
        class="flex justify-content-between lg:block border-top-1 lg:border-top-none surface-border py-3 lg:py-0 mt-3 lg:mt-0"
      >
        <a
          href="https://login.eetjethuis.nl/realms/eetjethuis/protocol/openid-connect/registrations?client_id=app-vue&redirect_uri=https%3A%2F%2Feetjethuis.nl%2Fapp%2F&state=9e5517e6-8f13-4c3a-86f8-12c49a982799&response_mode=fragment&response_type=code&scope=openid&nonce=1da7f9fb-8ce9-4322-b954-05b9151de9e5"
          ><Button
            label="Registreer"
            class="p-button-rounded p-button-raised border-none font-light text-white line-height-2"
          ></Button
        ></a>
        <a href="/app/">
          <Button
            label="Login"
            class="p-button-raised p-button-rounded ml-5 border-none font-light line-height-2"
            style="background-color: var(--ejtorange)"
          ></Button
        ></a>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "NavBar",
};
</script>

<script setup>
const smoothScroll = (id) => {
  document.querySelector(id).scrollIntoView({
    behavior: "smooth",
  });
};
</script>

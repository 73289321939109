<template>
  <navbar />
  <HomepageLanding></HomepageLanding>
  <HomepageDevices />
  <HomepagePlusses></HomepagePlusses>
  <HomepageReview />
  <HomepageStats></HomepageStats>
  <HomepageContact></HomepageContact>
  <HomepageDonation></HomepageDonation>
  <Divider />
  <HomepageFooter></HomepageFooter>
  <ScrollTop style="background-color: var(--ejtblue)" />
</template>

<script>
import navbar from "../components/NavbarHomepage/navbar.vue";
import HomepageLanding from "../components/Homepage/HomepageLanding.vue";
import HomepagePlusses from "../components/Homepage/HomepagePlusses.vue";
import HomepageStats from "../components/Homepage/HomepageStats.vue";
import HomepageContact from "../components/Homepage/HomepageContact.vue";
import HomepageFooter from "../components/Homepage/HomepageFooter.vue";
import HomepageDonation from "../components/Homepage/HomepageDonation.vue";
import HomepageReview from "../components/Homepage/HomepageReview.vue";
import HomepageDevices from "../components/Homepage/HomepageDevices.vue";
export default {
  name: "Homepage",
  components: {
    navbar,
    HomepageLanding,
    HomepagePlusses,
    HomepageStats,
    HomepageContact,
    HomepageFooter,
    HomepageDonation,
    HomepageReview,
    HomepageDevices,
  },
};
</script>

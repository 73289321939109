import { createRouter, createWebHistory } from "vue-router";
import Homepage from "../views/Homepage.vue";
import Landing from "../views/Landing.vue";

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes: [
    // {
    //   path: "/",
    //   name: "Landing",
    //   component: Landing,
    // },
    {
      path: "/",
      name: "Homepage",
      component: Homepage,
    },
  ],
});

export default router;

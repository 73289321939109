<template>
  <div
    class="surface-ground py-4 px-4 lg:px-8 mx-0 md:pt-8 mt-6 lg:mx-8"
    id="donations"
  >
    <div class="text-900 font-bold text-6xl mb-4 text-center">Doneer nu!</div>
    <div class="text-700 text-xl mb-6 text-center line-height-3">
      We willen Eetjethuis.nl graag gratis aanbieden zonder advertenties. Maar
      om Eetjethuis online te houden zijn er wel kosten aan verboden. Ben je
      blij met Eetjethuis.nl en wil je ons supporten? Dan kunt u hieronder
      doneren. Elk kleine beetje helpt!
    </div>

    <div class="grid justify-content-center">
      <div class="col-12 lg:col-4">
        <div class="p-3 h-full">
          <div
            class="shadow-2 p-3 h-full flex flex-column surface-card"
            style="border-radius: 6px"
          >
            <div class="text-900 font-medium text-xl mb-2">Gratis</div>
            <div class="text-600">
              De applicatie is volledig gratis te gebruiken!
            </div>
            <hr class="my-3 mx-0 border-top-1 border-none surface-border" />
            <div class="flex align-items-center">
              <span class="font-bold text-2xl text-900">&euro;0,00</span>
              <span class="ml-2 font-medium text-600">Eenmalig</span>
            </div>
            <hr class="my-3 mx-0 border-top-1 border-none surface-border" />
            <ul class="list-none p-0 m-0 flex-grow-1">
              <li class="flex align-items-center mb-3">
                <i class="pi pi-check-circle text-green-500 mr-2"></i>
                <span>volledige functionaliteiten</span>
              </li>
              <li class="flex align-items-center mb-3">
                <i class="pi pi-check-circle text-green-500 mr-2"></i>
                <span>Unlimited contact met team Eetjethuis</span>
              </li>
              <li class="flex align-items-center mb-3">
                <i class="pi pi-times-circle text-red-500 mr-2"></i>
                <span
                  >Help mee met het onderhouden en online houden van
                  Eetjethuis.nl</span
                >
              </li>
            </ul>
            <hr
              class="mb-3 mx-0 border-top-1 border-none surface-border mt-auto"
            />
            <Button
              label="Naar de app"
              class="p-3 w-full p-button-outlined"
            ></Button>
          </div>
        </div>
      </div>

      <div class="col-12 lg:col-4">
        <div class="p-3 h-full">
          <div
            class="shadow-2 p-3 h-full flex flex-column surface-card"
            style="border-radius: 6px"
          >
            <div class="text-900 font-medium text-xl mb-2">Donatie</div>
            <div class="text-600">Kies zelf een bedrag om te doneren.</div>
            <hr class="my-3 mx-0 border-top-1 border-none surface-border" />
            <div class="flex align-items-center">
              <span>v.a. </span>
              <span class="ml-2 font-bold text-2xl text-900"> &euro;1,-</span>
              <span class="ml-2 font-medium text-600">Eenmalig</span>
            </div>
            <hr class="my-3 mx-0 border-top-1 border-none surface-border" />
            <ul class="list-none p-0 m-0 flex-grow-1">
              <li class="flex align-items-center mb-3">
                <i class="pi pi-check-circle text-green-500 mr-2"></i>
                <span>Eenmalige donatie</span>
              </li>
              <li class="flex align-items-center mb-3">
                <i class="pi pi-check-circle text-green-500 mr-2"></i>
                <span>volledige functionaliteiten</span>
              </li>
              <li class="flex align-items-center mb-3">
                <i class="pi pi-check-circle text-green-500 mr-2"></i>
                <span>Unlimited contact met team Eetjethuis</span>
              </li>
              <li class="flex align-items-center mb-3">
                <i class="pi pi-check-circle text-green-500 mr-2"></i>
                <span
                  >Help mee met het onderhouden en online houden van
                  Eetjethuis.nl</span
                >
              </li>
            </ul>
            <hr
              class="mb-3 mx-0 border-top-1 border-none surface-border mt-auto"
            />
            <Button label="Doneer nu!" class="p-3 w-full"></Button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "HomepageDonation",
};
</script>

<template>
  <div id="review" class="py-4 px-4 lg:px-8 mt-5 mx-0 lg:mx-8">
    <div class="grid justify-content-center">
      <div
        class="col-12 p-2 md:p-8"
        style="
          border-radius: 20px;
          background: linear-gradient(
              0deg,
              rgba(255, 255, 255, 0.6),
              rgba(255, 255, 255, 0.6)
            ),
            radial-gradient(
              77.36% 256.97% at 77.36% 57.52%,
              var(--ejtblue) 0%,
              white 100%
            );
        "
      >
        <div
          class="flex flex-column justify-content-center align-items-center text-center px-3 py-3 md:py-0"
        >
          <h3 class="text-gray-900 text-3xl mb-2">Frank</h3>
          <span class="text-gray-500 text-2xl"
            >-- Testgebruiker tijdens de productie van de applicatie --</span
          >
          <p
            class="text-gray-900 sm:line-height-2 md:line-height-4 text-xl mt-4"
            style="max-width: 800px"
          >
            “Scott kwam met het idee om een applicatie te maken, waarin je kon
            aangeven wanneer je thuis wilde eten. Hierdoor hoefde ik als ouder
            niet continue aan mijn kinderen te vragen wanneer ze thuis eten. Ook
            kan ik hierdoor beter boodschappen doen, omdat ik terug kan kijken
            wie er wanneer wilt mee-eten”
          </p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "HomepageReview",
};
</script>

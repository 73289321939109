<template>
  <div id="devices" class="py-4 px-4 lg:px-8 mx-0 md:pt-8 mt-6 lg:mx-8">
    <div class="text-center">
      <h2 class="text-900 font-bold text-5xl mb-2">
        Geschikt voor elk device!
      </h2>
      <span class="text-600 text-2xl"
        >Deze toepassing, met zijn veelzijdigheid, is perfect voor elk apparaat!
        Als je een smartphone, tablet, laptop of desktop gebruikt, heb je altijd
        de beste prestaties. Ervaar een soepele applicatie, ongeacht het
        apparaat dat je kiest.</span
      >
    </div>

    <div class="grid mt-8 pb-2 md:pb-8 md:pt-8">
      <div
        class="flex justify-content-center col-12 lg:col-6 p-0 flex-order-1 lg:flex-order-0"
        style="border-radius: 8px; background-color: rgba(6, 182, 212, 0.4)"
      >
        <img
          src="@/assets/images/Eetjethuis-mobile.png"
          class="w-11"
          alt="mockup mobile"
        />
      </div>

      <div
        class="col-12 lg:col-6 my-auto flex flex-column lg:align-items-end text-center lg:text-right"
      >
        <div
          class="flex align-items-center justify-content-center align-self-center lg:align-self-end"
          style="
            width: 4.2rem;
            height: 4.2rem;
            border-radius: 10px;
            background-color: rgba(6, 182, 212, 0.4);
          "
        >
          <i
            class="pi pi-fw pi-mobile text-5xl"
            style="color: var(--ejtblue)"
          ></i>
        </div>
        <h2 class="line-height-1 text-900 text-4xl font-normal mt-4 mb-4">
          Mobiel device
        </h2>
        <span
          class="text-700 text-2xl line-height-3 ml-0 md:ml-2 mb-4"
          style="max-width: 650px"
        >
          Gebruiksgemak is binnen handbereik! Deze toepassing is geschikt voor
          elk apparaat, waaronder mobiele telefoons. Op jouw mobiele apparaat
          kun je de app moeiteloos gebruiken, dankzij een responsieve interface
          die zich aanpast aan jouw device.
        </span>
      </div>
    </div>

    <div class="grid mt-8 pt-2 md:pt-8">
      <div
        class="col-12 lg:col-6 my-auto flex flex-column text-center lg:text-left lg:align-items-start"
      >
        <div
          class="flex align-items-center justify-content-center align-self-center lg:align-self-start"
          style="
            width: 4.2rem;
            height: 4.2rem;
            border-radius: 10px;
            background-color: rgba(6, 182, 212, 0.4);
          "
        >
          <i
            class="pi pi-fw pi-desktop text-5xl"
            style="color: var(--ejtblue)"
          ></i>
        </div>
        <h2 class="line-height-1 text-900 text-4xl font-normal mt-4 mb-4">
          Desktop
        </h2>
        <span
          class="text-700 text-2xl line-height-3 ml-0 md:ml-2 mb-4"
          style="max-width: 650px"
        >
          Ontdek het gemak van onze applicatie, nu ook beschikbaar voor
          desktopgebruik! Geniet van dezelfde krachtige functionaliteiten en
          intuïtieve bediening die je gewend bent, maar dan op een groter
          scherm. Of je nu thuis of op kantoor bent.
        </span>
      </div>

      <div
        class="flex justify-content-end flex-order-1 sm:flex-order-2 col-12 lg:col-6 p-0"
        style="border-radius: 8px; background-color: rgba(6, 182, 212, 0.4)"
      >
        <img
          src="@/assets/images/Eetjethuis-desktop.png"
          class="w-11"
          alt="mockup"
        />
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "HomepageDevices",
};
</script>
